export const languages = ['english', 'tamil', 'telugu', 'malayalam', 'hindi', 'korean', 'tamil dubbed', 'kanada'];

export const adminMenu = [
    {
        id: 4,
        name: 'Home'
    },
    {
        id: 1,
        name: "Admin Chat"
    },
    {
        id: 2,
        name: "Upload Video To Drive"
    },
    {
        id: 3,
        name: "Manage Media"
    }
]