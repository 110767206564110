import React from 'react';
import { NavLink } from 'react-router-dom';
import MovieIcon from '@mui/icons-material/Movie';
import SearchIcon from '@mui/icons-material/Search';
import TvIcon from '@mui/icons-material/Tv';

export const Headeritems = () => {

  const header_list = [
    {
      title: 'Movies',
      link: '/',
      icon: <MovieIcon className="text-rose-700" style={{fontSize: "31px"}} />,
    },
    {
      title: 'Series',
      link: '/series',
      icon: <TvIcon className="text-rose-700" style={{fontSize: "31px"}} />,
    },
    {
      title: 'Search',
      link: '/search',
      icon: <SearchIcon className="text-rose-700" style={{fontSize: "31px"}} />,
    },
  ];

  return (
    <>
      <div className="lg:flex sm: hidden">
        {header_list.map((item, index) => (
          <NavLink
            to={item.link}
            key={index}
            className={({ isActive }) =>
              `p-2 m-3 text-2xl font-bold navitems relative group ${
                isActive ? 'text-rose-600' : 'text-white hover:text-rose-600 '
              }`
            }
          >
            <div className="flex gap-1 items-center">
              <span>{item.icon}</span>
              <span>{item.title}</span>
            </div>
            <span
              className={`absolute left-1/2 bottom-0 h-[2px] w-0 bg-rose-600 shadow-[0_0_6px_2px_rgba(255,255,255,0.3)]  opacity-0 transition-all duration-500 ease-out transform -translate-x-1/2 ${
                'group-hover:w-full group-hover:scale-x-100 group-hover:opacity-100 group-hover:left-1/2 ' +
                (item.link === window.location.pathname ? 'w-full opacity-100 left-1/2' : '')
              }`}
            ></span>
          </NavLink>
        ))}
      </div>
    </>
  );
};
